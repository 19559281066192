import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Parallax, ParallaxBanner, useParallax } from 'react-scroll-parallax';
import Div100vh from 'react-div-100vh';
import tracker from '../../tracker';
import CoupleImage from '../../component/couple-image/CoupleImage';
// import { ReactComponent as InLove } from '../../in-love.svg';

const HomePage = () => {
  return (
    <div className="nn-wrap">
      <div className="absolute scroll-me animated floating bottom-[10px] z-[999]">
        <div className="scroll-down opacity-50 -ml-[15px]"></div>
      </div>
      <Div100vh className="w-full">
        <ParallaxBanner
          className="mb-20 h-full"
          layers={[
            {
              expanded: false,
              image: '/imgs/bg.png',
            },
            {
              expanded: false,
              shouldAlwaysCompleteAnimation: true,
              children: (
                <div className="absolute left-0 top-0">
                  <img
                    className="pointer-events-none select-none max-w-[1300px] max-h-[1300px] w-[100vw] h-[100vw] -ml-[60%] -mt-[55%] rotate-[55deg]"
                    src="imgs/bg-pink-leaves.png"
                    alt=""
                  />
                </div>
              ),
            },
            {
              expanded: false,
              shouldAlwaysCompleteAnimation: true,
              children: (
                <div className="absolute right-0 bottom-0">
                  <img
                    className="pointer-events-none select-none max-w-[1300px] max-h-[1300px] w-[100vw] h-[100vw] ml-[60%] -mb-[50%] rotate-[230deg]"
                    src="imgs/bg-pink-leaves.png"
                    alt=""
                  />
                </div>
              ),
            },
            {
              speed: -15,
              translateY: -10,
              // shouldAlwaysCompleteAnimation: true,
              onEnter: () => tracker.track('section1_logo'),
              children: (
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                  <Fade
                    triggerOnce
                    direction="up"
                    className="aspect-square w-auto h-[60vh] max-w-[300px] max-h-[300px] md:max-w-[500px] md:max-h-[500px]"
                  >
                    <img
                      className="w-full h-full pointer-events-none select-none"
                      src="imgs/logo.png"
                      alt="Nan & Charge Logo"
                    />
                  </Fade>
                  <Fade
                    triggerOnce
                    delay={700}
                    direction="down"
                    className="w-3/4 md:w-2/4 lg:w-2/6 max-w-[780px]"
                  >
                    <img
                      className="w-full h-full pointer-events-none select-none"
                      src="imgs/text-target-date.png"
                      alt="Saturday, Auguest 26th 2023"
                    />
                  </Fade>
                </div>
              ),
            },
            /* {
            opacity: [0, 1, 'easeOutCubic'],
            shouldAlwaysCompleteAnimation: true,
            expanded: false,
            children: <div className="absolute inset-0 bg-gradient-to-t from-white to-transparent bottom-0" />,
          }, */
          ]}
        />
      </Div100vh>
      <div className="page-fade w-full overflow-hidden">
        <Parallax
          // speed={5}
          translateY={[]}
          scale={[1.5, 0.9]}
          opacity={[0, 1.5]}
          // className="absolute z-10 -mt-[40vh] lg:-mt-[95vh]"
        >
          <img
            className="m-auto"
            src="imgs/text-wedding-invitation.png"
            alt="Wedding Invitation"
          />
        </Parallax>
      </div>
      <div className="center-couple py-32">
        <div className="frame relative">
          <Parallax
            speed={-4}
            scale={[1, 1.3]}
            opacity={[1.5, 0.5]}
            className="relative z-[2] w-3/4 max-w-[800px] max-h-[800px] m-auto pointer-events-none select-none"
          >
            <img className="" src="imgs/frame-center.png" alt="NC" />
          </Parallax>
          <div className="couple w-full absolute top-0 z-[1]">
            <Parallax
              speed={-8}
              scale={[0.9, 1.2]}
              opacity={[0.2, 2]}
              shouldAlwaysCompleteAnimation
              className="w-3/4 max-w-[700px] max-h-[700px] m-auto pointer-events-none select-none"
              onEnter={() => tracker.track('section2_couple')}
            >
              <CoupleImage />
            </Parallax>
          </div>
        </div>
      </div>

      <div className="overflow-hidden">
        <Parallax
          speed={0}
          startScroll={20}
          scale={[0.8, 1.2]}
          opacity={[0.8, 1.5]}
        >
          <img
            className="w-full max-w-[960px] lg:w-[60vw]"
            src="imgs/text-nn.png"
            alt="Nalinnipa & Norrapat"
          />
        </Parallax>
      </div>
    </div>
  );
};

export default HomePage;
