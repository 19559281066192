import React from 'react';
import clsx from 'classnames';
import { Fade, Flip, JackInTheBox, Zoom } from 'react-awesome-reveal';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';
import { ReactComponent as InLove } from '../../in-love.svg';
import tracker from '../../tracker';

const DetailPage = ({ className }) => {
  return (
    <div
      className={clsx(
        'nn-wrap max-w-screen-xl pt-[10vw] md:pt-[90px] relative overflow-hidden',
        className
      )}
    >
      <div className="w-full absolute flex flex-row place-content-between px-2 top-0">
        <img
          className="w-[25vw] h-[25vw] rotate-180 max-w-[250px] max-h-[250px]"
          src="imgs/frame-border-1.png"
          alt=""
        />
        <img
          className="w-[25vw] h-[25vw] rotate-[270deg] max-w-[250px] max-h-[250px]"
          src="imgs/frame-border-2.png"
          alt=""
        />
      </div>

      <div className="px-10">
        <Fade
          triggerOnce
          delay={500}
          direction="left"
          onVisibilityChange={(inView) =>
            inView && tracker.track('section3_names')
          }
        >
          <img
            className="w-full hidden md:block"
            src="imgs/text-parents-name.png"
            alt="นายสำรอง โพธิ์มี นางปาลิดา โพธิ์มี และ นาวาโท อัศวิน นิ่มมณี นางธนพร นิ่มมณี"
          />
          <img
            className="w-full md:hidden"
            src="imgs/text-parents-name-alt.png"
            alt="นายสำรอง โพธิ์มี นางปาลิดา โพธิ์มี และ นาวาโท อัศวิน นิ่มมณี นางธนพร นิ่มมณี"
          />
        </Fade>
        <Fade triggerOnce delay={500} direction="right">
          <img
            className="w-full hidden md:block"
            src="imgs/text-invite-msg.png"
            alt="มีความยินดีขอเรียนเชิญท่าน ร่วมเป็นเกียรติในพิธีมงคลสมรส ระหว่าง"
          />
          <img
            className="w-full md:hidden"
            src="imgs/text-invite-msg-alt.png"
            alt="มีความยินดีขอเรียนเชิญท่าน ร่วมเป็นเกียรติในพิธีมงคลสมรส ระหว่าง"
          />
        </Fade>

        <div className="pb-4" />

        <div className="relative">
          <Zoom triggerOnce delay={500} direction="down" duration={1500}>
            <img
              className="w-full m-auto lg:max-w-[75%]"
              src="imgs/text-nalin.png"
              alt="นางสาวนลินนิภา โพธิ์มี"
            />
          </Zoom>
          <Parallax
            className="absolute -top-[2rem] -left-[1rem] w-[18vw]"
            speed={1}
          >
            <Zoom triggerOnce delay={700} direction="down" duration={1500}>
              <img
                className="w-full mr-auto max-w-[200px] filter-ds"
                src="imgs/frame-nick.png"
                alt="แนน"
              />
            </Zoom>
          </Parallax>
        </div>
        <Zoom triggerOnce delay={1200} duration={900}>
          <img
            className="w-full m-auto lg:max-w-[75%]"
            src="imgs/text-and-separator.png"
            alt="และ"
          />
        </Zoom>

        <div className="relative">
          <Zoom triggerOnce delay={500} direction="up" duration={1500}>
            <img
              className="w-full m-auto lg:max-w-[75%]"
              src="imgs/text-norrapat.png"
              alt="นายนรภัทร นิ่มมณี"
            />
          </Zoom>
          <Parallax
            className="absolute z-[2] -bottom-[1rem] -right-[1rem] w-[18vw]"
            speed={1}
          >
            <Zoom triggerOnce delay={700} direction="down" duration={1500}>
              <img
                className="w-full ml-auto max-w-[200px] filter-ds"
                src="imgs/frame-nick-2.png"
                alt="ชาร์จ"
              />
            </Zoom>
          </Parallax>
        </div>

        <Flip triggerOnce delay={600} direction="horizontal">
          <img
            className="w-full m-auto lg:max-w-[75%]"
            src="imgs/text-destination.png"
            alt="ณ สโมสรสัญญาบัตรโรงเรียนนายเรือ"
          />
        </Flip>
        <Fade triggerOnce delay={1000} direction="down">
          <img
            className="w-full m-auto lg:max-w-[75%]"
            src="imgs/text-target-date-th.png"
            alt="วันเสาร์ที่ 26 สิงหาคม 2566"
          />
        </Fade>

        <div className="w-100 pb-6 flex justify-center">
          <Fade
            triggerOnce
            delay={1400}
            direction="down"
            duration={2000}
            onVisibilityChange={(inView) =>
              inView && tracker.track('section3_timeline')
            }
          >
            <img
              className="w-full m-auto lg:max-w-[75%]"
              src="imgs/timeline.png"
              alt=""
            />
          </Fade>
        </div>

        <div className="w-100 pb-6 flex justify-center">
          <JackInTheBox
            delay={800}
            triggerOnce
            className=" w-[70%] max-w-[300px]"
          >
            <div className="flex flex-col items-center mx-4 p-[24px]">
              <img
                className="w-full mx-6"
                src="imgs/theme-colors.png"
                alt="ธีมสี ชมพู ครีม เขียวแตงดอง และเทา"
                onClick={() => tracker.track('section3_theme_click')}
              />
              <span className="pt-2 text-xl">ธีมงาน</span>
            </div>
          </JackInTheBox>
          <JackInTheBox
            delay={1200}
            triggerOnce
            className=" w-[70%] max-w-[300px]"
          >
            <a
              href="https://goo.gl/maps/pZpVwboiFv3u8Pos7"
              target="_blank"
              rel="noreferrer"
              className="flex flex-col items-center rounded-lg block mx-4 p-[24px] border-solid border-2 border-[#b77880]"
              onClick={() => tracker.track('section3_map_click')}
            >
              <img
                className="w-full mx-6"
                src="imgs/icon-map.png"
                alt="แผนที่"
              />
              <span className="pt-2 text-xl">แผนที่</span>
            </a>
          </JackInTheBox>
        </div>

        <Fade triggerOnce delay={500} direction="up">
          <img
            className="w-full"
            src="imgs/text-apologize.png"
            alt="ขออภัยหากมิได้เรียนเชิญด้วยตนเอง"
          />
        </Fade>
      </div>
    </div>
  );
};

export default DetailPage;
