import { ParallaxProvider } from 'react-scroll-parallax';

import './App.css';
import HomePage from './pages/home/HomePage';
import DetailPage from './pages/detail/DetailPage';
import tracker from './tracker';
import { useEffect } from 'react';

tracker.track('enter');

const trackIFrameCb = () => {
  setTimeout(() => {
    if (document.activeElement.tagName === 'IFRAME') {
      tracker.track('section4_video_click');
    }
  });
};

function App() {
  useEffect(() => {
    window.focus();

    window.addEventListener('blur', trackIFrameCb);

    return () => window.removeEventListener('blur', trackIFrameCb);
  }, []);

  return (
    <ParallaxProvider>
      <div>
        <HomePage />
        <DetailPage className="m-auto" />

        <div className="w-full md:w-[680px] m-auto overflow-hidden md:rounded-lg shadow-2xl">
          <iframe
            width="100%"
            height="515"
            src="https://www.youtube.com/embed/0Ri97uTt0ss?si=3e8DTdRrhm8AN_Nx"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <br />

        <div className="w-full md:w-[680px] m-auto overflow-hidden md:rounded-lg shadow-2xl">
          <iframe
            width="100%"
            height="515"
            src="https://www.youtube.com/embed/dDhS_yOVS88"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </ParallaxProvider>
  );
}

export default App;
