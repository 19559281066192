import React, { useMemo } from 'react';

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const CoupleImage = () => {
  const imageSrcs = useMemo(() => {
    const randomNum = getRandomInt(1, 5);

    return {
      src: `imgs/couple${randomNum}.png`,
      src2x: `imgs/couple${randomNum}@2x.png`,
    };
  }, []);

  return (
    <picture>
      <source media="(max-width: 399px)" srcSet={imageSrcs.src} />
      <source media="(min-width: 400px)" srcSet={imageSrcs.src2x} />
      <img className="" src={imageSrcs.src} alt="Nan & Charge in paint" />
    </picture>
  );
};

export default CoupleImage;
